import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import CasesCollage from "../components/casesCollage/casesCollage";

const CasesPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Our Cases" />
      <div className="cases-hero">
        <div className="container-wide">
          <div className="container">
            <div className="text-wrap">
              <h1
                dangerouslySetInnerHTML={{
                  __html: data.wpPage.casesHero.casesHero.title,
                }}
              />
              <p className="after-heading-text">
                {data.wpPage.casesHero.casesHero.subtitle}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CasesCollage data={data} />
    </Layout>
  );
};

export default CasesPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "cases" }) {
      casesHero {
        casesHero {
          title
          subtitle
        }
      }
    }
    allWpCase {
      edges {
        node {
          title
          content
          categories {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
    allWpCategory(
      filter: { name: { in: ["行业分类", "场景分类", "服务内容"] } }
    ) {
      edges {
        node {
          name
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`;
