import React, { useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import "./casesCollage.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cn from "classnames";
import { filter } from "lodash";

const CasesCollage = ({ data }) => {
  const [filtered, setFiltered] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([null, null, null]);

  const filters = data.allWpCategory.edges;
  const cases = data.allWpCase.edges;

  const setFilters = (filterName, index) => {
    setAppliedFilters(existingFilters => {
      return [
        ...existingFilters.slice(0, index),
        filterName,
        ...existingFilters.slice(index + 1),
      ];
    });
    setFiltered(true);
  };

  const clearFilters = () => {
    setAppliedFilters([null, null, null]);
    setFiltered(false);
  };

  return (
    <div className="cases-collage">
      <div className="container">
        <div className="cases-collage-filters">
          <Dropdown>
            <Dropdown.Toggle id="casesFilterIndustry">
              {appliedFilters[2] ? appliedFilters[2] : filters[2].node.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filters[2].node.wpChildren.nodes.map((filter, index) => (
                <Dropdown.Item
                  eventKey={1}
                  key={index}
                  onClick={() => setFilters(filter.name, 2)}
                >
                  {filter.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle id="casesFilterScene">
              {appliedFilters[0] ? appliedFilters[0] : filters[0].node.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filters[0].node.wpChildren.nodes.map((filter, index) => (
                <Dropdown.Item
                  eventKey={1}
                  key={index}
                  onClick={() => setFilters(filter.name, 0)}
                >
                  {filter.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle id="casesFilterService">
              {appliedFilters[1] ? appliedFilters[1] : filters[1].node.name}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filters[1].node.wpChildren.nodes.map((filter, index) => (
                <Dropdown.Item
                  eventKey={1}
                  key={index}
                  onClick={() => setFilters(filter.name, 1)}
                >
                  {filter.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {filtered && (
            <Button onClick={() => clearFilters()}>清空筛选条件</Button>
          )}
        </div>

        <div
          className={cn(
            "cases-collage-grid",
            { "cases-collage-grid-default": !filtered },
            { "cases-collage-grid-filtered": filtered }
          )}
        >
          {cases.map((item, index) => {
            const i = item.node;
            const image = getImage(i.featuredImage.node.localFile);
            const categories = [];
            i.categories.nodes.map(c => {
              categories.push(c.name);
              categories.push(null);
            });
            return (
              <div
                className={cn(
                  "cases-collage-grid-item",
                  `grid-item-${index + 1}`,
                  {
                    "cases-collage-grid-item-hidden": !appliedFilters.every(
                      r => categories.indexOf(r) >= 0
                    ),
                  }
                )}
                key={index}
              >
                <div className="cases-collage-grid-item-inner">
                  <div className="case-grid-item-image-wrap">
                    <GatsbyImage
                      className="case-grid-item-image"
                      image={image}
                      alt={i.title}
                    />
                  </div>
                  <div className="text-wrap">
                    <h2>{i.title}</h2>
                    <div className="case-tags">
                      {i.tags.nodes.map((tag, index) => {
                        return (
                          <span className="tag-wrap" key={index}>
                            {tag.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div className="case-grid-item-hover">
                    {i.content && (
                      <div
                        className="case-grid-item-content"
                        dangerouslySetInnerHTML={{ __html: i.content }}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CasesCollage;
